<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>Get Started</v-card-title>
      <v-card-text>
        Cometa Tools is a web software for convenient work with Discord, Twitter, Telegram services. To start using Cometa read the Get Started block
        in the <a href="https://docs.cometa.tools/cometa-tools-guide/discord-module/get-started">documentation</a>. Cometa Tools is a web-panel,
        so all actions with accounts can be performed from any device (even from the phone) with web-browser.
      </v-card-text>
    </v-card>

    <v-card class="mb-6">
      <v-card-title>Official links</v-card-title>
      <v-card-text>
        <v-list class="pl-1">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                {{ icons.mdiDiscord }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <a href="https://discord.com/channels/1024775090644205599/1025123349942259752/1026437377188712500">Discord</a>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                {{ icons.mdiTwitter }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <a href="https://twitter.com/CometaTools">Twitter</a>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                {{ icons.mdiBookOutline }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <a href="https://docs.cometa.tools/cometa-tools-guide/discord-module/get-started">Documentation</a>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                {{ icons.mdiMapMarkerPath }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <a href="https://docs.cometa.tools/cometa-tools-guide/roadmap/cometa-tools-roadmap">Roadmap</a>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                {{ icons.mdiYoutube }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <a href="https://www.youtube.com/channel/UCKSaamPNNZdG-4Y7RTanvhQ">YouTube</a>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card v-if="isChrome">
      <v-card-title>
        Chrome Extension
        <!-- <v-chip
          class="ml-2"
          color="cyan"
        >
          Soon
        </v-chip> -->
      </v-card-title>
      <v-card-text>
        Install special <a
          target="_blank"
          href="https://cometa.tools/chrome-extension.zip"
        >Extension for Cometa Tool</a> to improve your accessability of features

        <ol class="mt-4">
          <li>Download zip archive</li>
          <li>Unpack archive</li>
          <li>Open Extension manager and enable Developer mode</li>
          <li>Click 'Load unpacked' button and select extension folder </li>
        </ol>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiDiscord, mdiTwitter, mdiBookOutline, mdiMapMarkerPath, mdiYoutube,
} from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiDiscord, mdiTwitter, mdiBookOutline, mdiMapMarkerPath, mdiYoutube,
      },
      isChrome: !!window.chrome,
    }
  },
}
</script>
